.App {
  text-align: center;
}

body {
  height: 100vh;
  background-color: rgb(0, 0, 0) !important;
}

.navbar {
 /* background-color: #4d0250;*/
 background-image: linear-gradient(rgb(74, 67, 75), rgb(0, 0, 0));
 border-bottom: 1px solid rgb(90, 90, 90);
  
}



.mowali-brand-logo {
  height: 50px;
}

.landing-container {
  background-image: linear-gradient(rgb(56, 56, 56), rgb(0, 0, 0));
  background-color: #141414 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: calc(100vh - 75px); */

}

.landing-page-text {
  color: rgb(202, 202, 202);
  display: flex;
  align-items: center;
}

.landing-paragraph {
  padding: 2rem;
}

.landing-text-feature-list {
  list-style-type: none;
  padding-left: 0;
  /* Optional: Remove left padding for a cleaner look */
}

.watch-list-container {
  color: white;
}




/* Style the color of the slider (the three horizontal lines) */
.custom-navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(110, 83, 113, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Style the slider color when it's in an active (open) state */
.custom-navbar .navbar-toggler-icon.open {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 255, 0, 0.55)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.welcome-logo-text {
  height: 30px;
}

.landing-logo {
  margin-top: 20px;
}

.get-started-button {
  border-radius: 25px !important;
  margin-bottom: 25px;
}

.logo-image {
  padding-top: 20px;
}

.nav-links {
  display: inline-block; 
  text-decoration: none;
  border-bottom: 2px solid white;
  /*background-color: #5f046b !important;*/
  color: #cfcfcf !important;  
  margin: 0px;
}

.nav-links:hover {
  /*background-color: #6e3475 !important;*/
  border-bottom: 2px solid white;
}

.movie-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.movie-card {
  margin: 10px;
}

.add-button {
  display: flex;
  justify-content: flex-end;
}

.search-movie-button {
  background-color: #771c7a !important;
  border: none !important;
}

.search-movie-button:hover {
  background-color: #902194 !important;
  border: none !important;
}


.reset-movie-button {
  background-color: #4f4250 !important;
  border: none !important;
}

.reset-movie-button:hover {
  background-color: #866f88 !important;
  border: none !important;
}

.search-movie-text {
  height: 35px;
  color: white;
  margin-top: 2px;
  background-color: #372c3a !important;
  width: 300px;
  border-radius: 8px;
}

.FaveMovie {
  display: flex;
  flex-wrap: wrap;

}

.fav-card {
  margin: 15px;
  border: 1px solid white;
}

.watched-button {
  margin-right: 10px;
}

.watched-button-color {
  margin-right: 10px;
  background-color: #f5d49d !important;
  border: none !important;
}

.watch-button-color {
  margin-right: 10px;

}

.footer-container {
  height: 50px;
  display: flex;
  justify-content: center;

}

.random-movie-selected {
  color: white;
  height: 750px;
}

.random-button{
  margin-top: 15px;
  border: none !important;
  background-color: #5f046b !important;
}

.random-button:hover{
  border: none !important;
  background-color: #730681 !important;
}

.form-container {
  max-width: 100%; /* Adjust this value as needed */
  margin: 0 auto; /* Center the form */
  padding: 15px;
  /* border: solid 2px red; */
}

.form-container .form-control, .form-container .btn {
  width: 80%; /* Full width */
  
}

.modal-title-centered {
  width: 100%;
  text-align: center;
}

/* Alternatively, if you want to center the whole header including the close button */
.modal-header {
  display: flex;
  justify-content: center;
}

.login-modal {  
  backdrop-filter: blur(4px);
}


.checkbox-and-link {
  display: flex;
  justify-content: space-between;
}

.register-link {
  margin-left: -10px; /* Adjust as needed */
}

.register-form{
  /* border: solid 2px red; */
  border-radius: 10px;
  margin-top: 20px;
  align-items: center; 
  background-color: white;
  padding: 15px;

  width: 100%; /* Default to full width for small devices */

  /* Larger than mobile devices (e.g., tablets) */
  @media (min-width: 768px) {
    width: 75%; /* Reduce width for medium screens */
  }

  /* Larger than tablets (e.g., desktops) */
  @media (min-width: 992px) {
    width: 50%; /* Further reduce width for large screens */
  }

  /* Large desktops and high-resolution displays */
  @media (min-width: 1200px) {
    width: 50%; /* Optionally, reduce even more for very large screens */
  }
}

.register-form-button{
  width:10px;
}

.center{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.add-top-padding{
  padding-top: 20px;
}

.register-label{
  color: white;
}

.reg-error-text{
  color: red;
}

.logged-in-user{
  color: #ca2a47;
  padding: 8px;
}

.log-out-link{
  color:#f57f0a!important;
}